.Footer {
  display: flex;
  justify-content: center;

  width: 100%;
  min-height: 24rem;
  padding: 1rem;
  padding-top: 6rem;
  background-color: rgb(14, 52, 90);

  color: #c0c0c0;

  font-size: 20px;
}

.FooterContainer {
  text-align: center;
  width: 100%;
}

.Columns {
  justify-content: center;
  text-align: center;
  width: 100%;
}

.Link {
  color: rgb(168, 168, 168);
}

a.Link:hover {
  color: rgb(223, 223, 223);
}
.Columns {
  align-content: flex-start;
}
.Column {
  padding: 0.2rem;
  margin: 0.2rem;
}
