.RichTextContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.RichText {
  padding: 1.6rem;
  width: 100%;
  min-width: 890px;

  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.5rem;
  font-display: swap;

  color: rgba(0, 0, 0, 0.87);
}
@media screen and (max-width: 768px) {
  .RichText {
    padding: 0.1rem;
    min-width: 300px;
  }
}

.RichText ul > li {
  list-style: initial;
}

.RichText span,
.RichText div,
.RichText p,
.RichText strong {
  font-family: inherit;
}
