body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-display: swap;
}

a {
  color: rgb(85, 26, 139);
}

html {
  scroll-behavior: smooth;
}

.flex-center {
  display: flex;
  align-items: center;
}
.p2 {
  padding: 2rem;
}
.pl-1 {
  padding-left: 1rem !important;
}
.pr-1 {
  padding-right: 1rem !important;
}
.m1 {
  margin: 1rem;
}
.m2 {
  margin: 2rem;
}
.mr-1 {
  margin-right: 1rem;
}
.ml-1 {
  margin-left: 1rem!important;
}
.mr-2 {
  margin-right: 2rem!important;
}
.ml-2 {
  margin-left: 2rem;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.pointer {
  cursor: pointer;
}

.gray {
  color: gray
}

.b2 {
  border-width: 2px;
}