

.modalCard {
  min-width: 720px;
}

@media screen and (max-width: 768px) {
  .modalCard {
    min-width: 300px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

