.Container {
    display: block;
    border: solid rgb(192, 192, 192) 1px;
    border-radius: 5px;
  
    overflow-y: scroll;
    height: 33vh;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.item {
    display: block;
    border: solid rgb(225 225 225) 1px;
    padding-top:8px;
    padding-bottom:8px;
    text-align: center;
    color:rgb(156, 155, 155);
   
}

.itemEnabeld {
    cursor:pointer;
    color:rgb(96, 96, 96);
    font-weight: 400;
}

.itemSelected {
    font-weight: 800;
    background-color: #f3f39d;
}

.Summary {
    display: block;
    margin:1rem;
    text-align: center;
    font-weight: 800;
}

.Loading {
    font-style: italic;
    color: #666;
    padding:4rem;
    display: block;
    text-align: center;
}