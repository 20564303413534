.content {
    margin-bottom: 6rem !important;
}

.h2 {
    font-size: 1.75em;
    margin-top: 2rem;
    margin-bottom: 0.5714em;
    color: #363636;
    font-weight: 600;
    line-height: 1.125;
}

.articleTitle {

    font-weight: 700;
    font-family: Arial;
    font-size: 2.2rem
}

.articleSummary {
    font-family: Arial;
    font-size: 1.4rem;
}

.blocPaiement {
    display: block;
    text-align: center;
    margin: auto;
    width: 100%;
}



.NavBar {
    height: 7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  .NavBarBrand {
    z-index: 2;
  }
  
  .Bearer {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 200;
    border-color: rgb(14, 52, 90);
    border-width: 2px;
    font-size: 1.6rem;
    border-style: solid;
    margin: 0.2rem;
  
    padding-right: 0.6rem;
  }
  
  @media screen and (max-width: 768px) {
    .Bearer {
      padding-right: 0;
    }
  }
  .Bearer > span:nth-child(1) {
    color: white;
    background-color: rgb(14, 52, 90);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    margin: 0;
    display: inline-block;
    padding: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    text-transform: uppercase;
    font-display: swap;
  }
  .Bearer > span:nth-child(2) {
    color: black;
    background-color: white;
    margin: 0;
    font-size: 1.4rem;
    font-family: "Arial Narrow", Arial, Helvetica, sans-serif;
    padding: 0.2rem;
    letter-spacing: 0px;
    padding-left: 0.6rem;
    font-display: swap;
  }
  .menu {
    justify-content: flex-end;
  }
  .retourBtn {
    border-color: rgb(14, 52, 90);
    border-width: 2px;
    font-size: 1.6rem;
    border-style: solid;
    margin: 0.2rem;
    color: black;
    background-color: white;
    margin: 0;
    font-size: 1.4rem;
    font-family: "Arial Narrow", Arial, Helvetica, sans-serif;
    letter-spacing: 0px;
    padding: 0.3rem;
    margin-right: 1rem;
    padding-right: 0.6rem;
    padding-left: 0.6rem;
  } 