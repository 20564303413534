
.prefs {
}

.prefsContent {
  font-size: 20px;
  text-align: left;
  padding: 1rem;
}
@media screen and (max-width: 768px) {
  .prefsContent {
    font-size: 14px;
  }
}

.prefsContentTitle {
  font-weight: bold;
  margin-top: 0.8rem;
}
