.banner {
  position: fixed;
  margin: 0;

  bottom: 0;
  width: 100%;

  background-color: #dfe9f6f9;

  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  font-size: larger;
}
@media screen and (max-width: 768px) {
  .banner {
  }
}

.head {
  padding: 1rem;
  display: block;
  color: rgb(14, 52, 90);
}
.buttonBar {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  flex-wrap: wrap;
}

.button {
  cursor: pointer;
  min-width: 140px;
  min-height: 44px;
  color: white;
  border-color: rgb(14, 52, 90);
  border-width: 3px;
  background-color: rgb(14, 52, 90);
  font-size: large;

  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
}

.buttonAccept {
  cursor: pointer;
  min-width: 140px;
  min-height: 44px;
  color: white;
  border-color: rgb(14, 52, 90);
  border-width: 3px;
  border-style: solid;
  background-color: rgb(14, 52, 90);
}

.buttonReject {
  color: rgb(63, 104, 145);
  border-color: rgb(63, 104, 145);
  background-color: white;
}
.buttonConfig {
  color: rgb(63, 104, 145);
  border-color: rgb(63, 104, 145);
  background-color: white;
}

.footer {
  margin: 1rem;
}