.Card {
  width: 230px;
  min-height: 230px;

  overflow: hidden;
  padding: 0.2rem;
  margin: 0.2rem;
}
.Image {
  padding: 0.5rem;
  /*box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;*/
}
.Image > img {
  object-fit: cover;
  padding: 0;
}

.CardContent {
  transition: transform 1s ease-out 0s;
  transform-origin: top left; /* add this in */
  transform-origin: left top;
  animation: normal;
  min-height: 220px;
  width: 200px;
  transform: scale(1, 1); /* no translate here */
  color: #444;
  background-color: #eee;
}
.CardContent:hover {
  transform: scale(1.04, 1.04); /* no translate here */
  /*box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;*/
  background-color: #ddd;
  color: black;
}

.CardCaption {
  text-align: center;
  font-weight: 700;
  min-height: 48px;
}
