@media screen and (max-width: 1460px) and (min-width: 1023px) {
  .SearchBar {
    position: absolute;
    right: 60px;
    top: 80px;
  }
}
@media screen and (max-width: 1023px) {
  .SearchBar {
    margin-left: 10px;
  }
}

.Button {
  background-color: rgb(14, 52, 90);
  color: #dfdfdf;
}

.Field {
  position: relative;
}

.Result {
  position: absolute;
  top: 46px;
  left: 0;
  width: 100%;
  min-width: 400px;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
}
.ResultItem {
  cursor: pointer;
}
.ResultItem:nth-child(even) {
  background-color: #fafafa;
}
