.Kbd {
  white-space: pre-wrap;
  font-size: 75%;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  border: 1px solid black;
  border-radius: 3px;
  padding: 0.2em 0.4em;
  line-height: normal;
  margin-right: 0.2em;
  box-shadow: 2px 2px 3px 0 rgb(0 0 0 / 75%);
  font-display: swap;
}
.Highlight {
  background-color: #fef3b7;
}

h1.Titre1 {
  font-weight: 700;
  font-family: arial, georgia, palatino, "book antiqua", "palatino linotype", serif;
  margin: 0rem 0 4px;
  font-size: 4.8rem;
  line-height: 1.4;
  margin-bottom: 0rem;
  font-display: swap;
}
@media screen and (max-width: 768px) {
  h1.Titre1 {
    font-size: 2rem;
    line-height: 1.3rem;
    margin-bottom: 0rem;
  }
}
h2.Titre2 {
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 3rem;
}

h3.Titre3 {
  margin: 1em 0 1px;
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1.3;
  color: #434343;
}

h1.Titre1 span,
h1.Titre1 div,
h1.Titre1 p,
h1.Titre1 strong {
  font-family: inherit;
  font-display: swap;
}
