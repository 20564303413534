.NavBar {
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.NavBarBrand {
  z-index: 2;
}

.Bearer {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 200;
  border-color: rgb(14, 52, 90);
  border-width: 2px;
  font-size: 1.6rem;
  border-style: solid;
  margin: 0.2rem;

  padding-right: 0.6rem;
}

@media screen and (max-width: 768px) {
  .Bearer {
    padding-right: 0;
  }
}
.Bearer > span:nth-child(1) {
  color: white;
  background-color: rgb(14, 52, 90);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin: 0;
  display: inline-block;
  padding: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  text-transform: uppercase;
  font-display: swap;
}
.Bearer > span:nth-child(2) {
  color: black;
  background-color: white;
  margin: 0;
  font-size: 1.4rem;
  font-family: "Arial Narrow", Arial, Helvetica, sans-serif;
  padding: 0.2rem;
  letter-spacing: 0px;
  padding-left: 0.6rem;
  font-display: swap;
}
.Burger {
}
.Menu {
  position: absolute;
  top: 26px;
  right: 0;
  justify-content: end;
  font-size: 20px;
}
@media screen and (max-width: 1023px) {
  .Menu {
    position: absolute;
    top: 100px;
    min-height: 100px;
    width: 100%;
    left: 0;
    justify-content: end;
  }
}
.Menu ul {
  display: flex;
}

@media screen and (max-width: 768px) {
  .Menu ul {
    display: flex;
    flex-direction: column;
  }
} 

.Menu ul > li {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  padding: 1rem;
  color: rgb(14, 52, 90);
}
.Menu ul > li:hover {
  border-color: rgb(123, 164, 209);
}

.Menu ul > li > a {
  padding: 0.3rem;
  color: rgb(14, 52, 90);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgb(181, 197, 214);
  transition: border-bottom-color 0.4s ease 0s;
}
.Menu ul > li > a:hover {
  border-bottom-style: solid;
  border-bottom-color: rgb(39, 96, 158);
}
