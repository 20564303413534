.cartItemCells {
  vertical-align: middle !important;
}

.cartTable {
  width: 100%;
}

.cartTotalLabelCells {
  text-align: end !important;
}
.cartItemImg {
  object-fit: cover;
  max-height: 120px;
}


.cartItemDelIcon {
    cursor: pointer;
    color: #7f1919;
}

.emptyCart {
    text-align: center;
    padding: 1.5rem;
    font-style: italic;
    font-size: larger;
    color: #b9b9b9;
}